<template>
  <div>
    <v-bottom-navigation color="info" horizontal :background-color="color" dark>
      <v-btn to="/college/temporarycollegeemreg" class="mr-5">
        <span>คัดเลือก/แข่งขัน บุคลากร </span>
        <v-icon>mdi-numeric-1-box</v-icon>
      </v-btn>

      <v-btn to="/college/temporaryemployees" class="mr-5">
        <span>การสมัคร</span>
        <v-icon>mdi-numeric-2-box</v-icon>
      </v-btn>

    </v-bottom-navigation>

    <v-container fluid="">
      <base-material-card
        icon="mdi-clipboard-text"
        title="ประกาศ คัดเลือก/แข่งขัน บุคลากร"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" lg="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" lg="6" class="text-right">
              <v-btn
                large
                right
                depressed
                color="primary"
                @click.native="temporarycollegeemregAdd()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="temporarycollegeemregs"
          :search="search"
        >
          <template v-slot:item.temCollegeEmRegPosition="{ item }">
            <span v-if="item.temCollegeEmRegPosition === 'governmentteach'"
              >พนักงานราชการ(สายการสอน)</span
            >
            <span v-else-if="item.temCollegeEmRegPosition === 'governmentsp'"
              >พนักงานราชการ(สายสนับสนุน)</span
            >
            <span v-else-if="item.temCollegeEmRegPosition === 'temporary'"
              >ครูอัตราจ้าง</span
            >
            <span v-else-if="item.temCollegeEmRegPosition === 'temporary_job'"
              >ลูกจ้างชั่วคราว</span
            >
          </template>

          <template v-slot:item.temCollegeEmRegHiring="{ item }">
            <span v-if="item.temCollegeEmRegHiring === 'month'"
              >รายเดือน</span
            >
            <span v-else-if="item.temCollegeEmRegHiring === 'day'"
              >รายวัน</span
            >
            <span v-else-if="item.temCollegeEmRegHiring === 'hour'"
              >รายชั่วโมง</span
            >
          </template>

          <template v-slot:item.temCollegeEmRegDateEnd="{ item }">
            {{
              item.temCollegeEmRegDateEnd
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>

          <template v-slot:item.temCollegeEmRegDetail="{ item }">
            <v-btn
              icon
              :href="'/HRvecfiles/' + item.temCollegeEmRegDetail"
              target="_blank"
            >
              <v-icon color="info">mdi-file-document</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.temCollegeEmStatus="{ item }">
            <v-icon v-if="item.temCollegeEmStatus === '1'" color="green"
              >mdi-lightbulb-on</v-icon
            >
            
            <v-icon v-else-if="item.temCollegeEmStatus === '0'" color="black"
              >mdi-lightbulb</v-icon
            >
        
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              color="yellow"
              @click.stop="temporarycollegeemregEdit(item.temCollegeEmRegID)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon
              color="red"
              @click.stop="temporarycollegeemregDelete(item.temCollegeEmRegID)"
            >
              mdi-delete
            </v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--addtemporarycollegeemregdialog  -->
      <v-layout row justify-center>
        <v-dialog
          v-model="addtemporarycollegeemregdialog"
          persistent
          max-width="70%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="เพิ่มข้อมูลประกาศ คัดเลือก/แข่งขัน บุคลากร"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="addtemporarycollegeemregform" lazy-validation>
                <v-container no-gutters>
                  <v-layout wrap>
                    <v-flex md6 class="pa-1">
                      <v-select
                        :items="position_select"
                        item-text="text"
                        item-value="value"
                        prepend-icon="mdi-account-settings"
                        outlined
                        label="ตำแหน่ง"
                        v-model="
                          addtemporarycollegeemreg.temCollegeEmRegPosition
                        "
                        required
                        :rules="[v => !!v || '']"
                      ></v-select>
                    </v-flex>

                    <v-flex md6 class="pa-1">
                      <v-select
                        :items="hiring"
                        item-text="text"
                        item-value="value"
                        prepend-icon="mdi-information-outline"
                        outlined
                        label="การจ้าง"
                        v-model="addtemporarycollegeemreg.temCollegeEmRegHiring"
                        required
                        :rules="[v => !!v || '']"
                      ></v-select>
                    </v-flex>

                    <v-flex md12 class="pa-1">
                      <v-textarea
                        prepend-icon="mdi-account-settings"
                        rows="4"
                        outlined
                        label="รายละเอียดตำแหน่ง"
                        v-model="
                          addtemporarycollegeemreg.temCollegeEmRegPositionDetail
                        "
                        required
                        :rules="[v => !!v || '']"
                      ></v-textarea>
                    </v-flex>

                    <v-flex md6 class="pa-1">
                      <v-text-field
                        prepend-icon="mdi-currency-btc"
                        outlined
                        label="อัตราค่าจ้างเดือนละ"
                        type="number"
                        v-model="addtemporarycollegeemreg.temCollegeEmRegSalary"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6 class="pa-1">
                      <v-text-field
                        prepend-icon="mdi-calendar"
                        outlined
                        type="date"
                        label="ปิดรับสมัครวันที่"
                        v-model="
                          addtemporarycollegeemreg.temCollegeEmRegDateEnd
                        "
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6 class="pa-1">
                      <v-text-field
                        prepend-icon="mdi-currency-btc"
                        type="number"
                        outlined
                        label="ค่าธรรมเนียมสมัคร"
                        v-model="addtemporarycollegeemreg.temCollegeEmPay"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6 class="pa-1">
                      <v-file-input
                        v-model="documentsLinkfiles"
                        accept=".pdf"
                        name="documentsLinkfiles"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์ประกาศ.pdf"
                        placeholder="รายละเอียดไฟล์ ประกาศ"
                        outlined
                        :show-size="1000"
                        :rules="rules"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-switch
                        prepend-icon="mdi-timer-off"
                        v-model="addtemporarycollegeemreg.temCollegeEmStatus_sw"
                        label="เปิด/ปิด รับสมัคร"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="addtemporarycollegeemregdialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="addtemporarycollegeemregSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deletetemporarycollegeemregdialog -->
      <v-layout>
        <v-dialog
          v-model="deletetemporarycollegeemregdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูลประกาศ คัดเลือก/แข่งขัน บุคลากร"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deletetemporarycollegeemregform" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>

                          ยืนยันการลบข้อมูลประกาศ คัดเลือก/แข่งขัน บุคลากร
                          <v-chip color="info" class="font-weight-bold"> 
                          <span
                            v-if="
                              edittemporarycollegeemreg.temCollegeEmRegPosition === 'governmentteach'
                            "
                            >พนักงานราชการ(สายการสอน)</span
                          >
                          <span
                            v-else-if="
                              edittemporarycollegeemreg.temCollegeEmRegPosition === 'governmentsp'
                            "
                            >พนักงานราชการ(สายสนับสนุน)</span
                          >
                          <span
                            v-else-if="
                              edittemporarycollegeemreg.temCollegeEmRegPosition === 'temporary'
                            "
                            >ครูอัตราจ้าง</span
                          >
                          <span
                            v-else-if="
                              edittemporarycollegeemreg.temCollegeEmRegPosition === 'temporary_job'
                            "
                            >ลูกจ้างชั่วคราว</span
                          >
                          {{
                            edittemporarycollegeemreg.temCollegeEmRegPositionDetail
                          }}
                          </v-chip>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="deletetemporarycollegeemregdialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deletetemporarycollegeemregSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model edittemporarycollegeemregdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="edittemporarycollegeemregdialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขประกาศ คัดเลือก/แข่งขัน บุคลากร"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="edittemporarycollegeemregform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6 class="pa-1">
                      <v-select
                        :items="position_select"
                        item-text="text"
                        item-value="value"
                        prepend-icon="mdi-account-settings"
                        outlined
                        label="ตำแหน่ง"
                        v-model="
                          edittemporarycollegeemreg.temCollegeEmRegPosition
                        "
                        required
                        :rules="[v => !!v || '']"
                      ></v-select>
                    </v-flex>

                    <v-flex md6 class="pa-1">
                      <v-select
                        :items="hiring"
                        item-text="text"
                        item-value="value"
                        prepend-icon="mdi-information-outline"
                        outlined
                        label="การจ้าง"
                        v-model="
                          edittemporarycollegeemreg.temCollegeEmRegHiring
                        "
                        required
                        :rules="[v => !!v || '']"
                      ></v-select>
                    </v-flex>

                    <v-flex md12 class="pa-1">
                      <v-textarea
                        prepend-icon="mdi-account-settings"
                        rows="4"
                        outlined
                        label="รายละเอียดตำแหน่ง"
                        v-model="
                          edittemporarycollegeemreg.temCollegeEmRegPositionDetail
                        "
                        required
                        :rules="[v => !!v || '']"
                      ></v-textarea>
                    </v-flex>

                    <v-flex md6 class="pa-1">
                      <v-text-field
                        prepend-icon="mdi-currency-btc"
                        outlined
                        label="อัตราค่าจ้างเดือนละ"
                        type="number"
                        v-model="
                          edittemporarycollegeemreg.temCollegeEmRegSalary
                        "
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6 class="pa-1">
                      <v-text-field
                        prepend-icon="mdi-calendar"
                        outlined
                        type="date"
                        label="ปิดรับสมัครวันที่"
                        v-model="
                          edittemporarycollegeemreg.temCollegeEmRegDateEnd
                        "
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6 class="pa-1">
                      <v-text-field
                        prepend-icon="mdi-currency-btc"
                        type="number"
                        outlined
                        label="ค่าธรรมเนียมสมัคร"
                        v-model="edittemporarycollegeemreg.temCollegeEmPay"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6 class="pa-1">
                      <div
                        v-if="edittemporarycollegeemreg.temCollegeEmRegDetail"
                        class="text-center"
                      >
                        ไฟล์ประกาศ
                        <v-btn
                          large
                          icon
                          :href="
                            '/HRvecfiles/' +
                              edittemporarycollegeemreg.temCollegeEmRegDetail
                          "
                          target="_blank"
                        >
                          <v-icon color="info">mdi-file-document</v-icon>
                        </v-btn>
                        <v-btn icon color="red" @click="deleteDocfile()">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                      <div v-else>
                        <v-file-input
                          v-model="documentsLinkfilesUpdate"
                          accept=".pdf"
                          name="documentsLinkfilesUpdate"
                          color="deep-purple accent-4"
                          counter
                          label="ไฟล์ประกาศ.pdf"
                          placeholder="รายละเอียดไฟล์ ประกาศ"
                          outlined
                          :show-size="1000"
                          :rules="rules"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="deep-purple accent-4"
                              dark
                              label
                              small
                            >
                              {{ text }}
                            </v-chip>

                            <span
                              v-else-if="index === 2"
                              class="text-overline grey--text text--darken-3 mx-2"
                            >
                              +{{ files.length - 2 }} File(s)
                            </span>
                          </template>
                        </v-file-input>
                      </div>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-switch
                        prepend-icon="mdi-timer-off"
                        v-model="
                          edittemporarycollegeemreg.temCollegeEmStatus_sw
                        "
                        label="เปิด/ปิด รับสมัคร"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="edittemporarycollegeemregdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="edittemporarycollegeemregSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      addtemporarycollegeemregdialog: false,
      edittemporarycollegeemregdialog: false,
      deletetemporarycollegeemregdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      temporarycollegeemregs: [],
      addtemporarycollegeemreg: {},
      edittemporarycollegeemreg: {},
      search: "",
      pagination: {},
      headers: [
        { text: "วิทยาลัย", align: "left", value: "college_name" },
        { text: "ตำแหน่ง", align: "left", value: "temCollegeEmRegPosition" },
        { text: "รายละเอียดตำแหน่ง", align: "left", value: "temCollegeEmRegPositionDetail" },
        {
          text: "รูปแบบการจ้าง",
          align: "left",
          value: "temCollegeEmRegHiring"
        },
        {
          text: "เงินจ้าง/เดือน",
          align: "left",
          value: "temCollegeEmRegSalary"
        },
        { text: "ปิดประกาศ", align: "center", value: "temCollegeEmRegDateEnd" },
        { text: "ค่าธรรมเนียม", align: "center", value: "temCollegeEmPay" },
        { text: "รายละเอียด", align: "center", value: "temCollegeEmRegDetail" },
        { text: "สถานะ", align: "center", value: "temCollegeEmStatus" },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบ",
          align: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      college: {},
      provinces: [],
      prefectures: [],

      collgegs: [],
      temporarycollegeemregstatus: [],
      regions: [],
      region_ena: true,
      documentsLinkfiles: null,
      documentsLinkfilesUpdate: null,
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB",
        value => !!value || ""
      ],
      position_select: [
        { text: "พนักงานราชการ(สายการสอน)", value: "governmentteach" },
        { text: "พนักงานราชการ(สายสนับสนุน)", value: "governmentsp" },
        { text: "ครูอัตราจ้าง", value: "temporary" },
        { text: "ลูกจ้างชั่วคราว", value: "temporary_job" }
      ],
      hiring: [
        { text: "รายเดือน", value: "month" },
        { text: "รายวัน", value: "day" },
        { text: "รายชั่วโมง", value: "hour" }
      ],
      user: []
    };
  },
  async mounted() {    
    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
    let result = await this.$http.post("user.php", {
      ApiKey: this.ApiKey,
      user_ID: userSession.user_ID
    });
    this.user = result.data;
    this.temporarycollegeemregQueryAll();
  },
  methods: {
    async temporarycollegeemregQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("temporarycollegeemreg.php", {
          ApiKey: this.ApiKey,
          temCollegeEmRegCode:this.user.user_code
        })
        .finally(() => (this.loading = false));
      this.temporarycollegeemregs = result.data;
    },

    async temporarycollegeemregAdd() {
      this.addtemporarycollegeemreg = {};
      this.addtemporarycollegeemregdialog = true;
    },

    async addtemporarycollegeemregSubmit() {
      let result = "";
      let uploaded = null;

      if (this.documentsLinkfiles != "") {
        let formData = new FormData();
        let filename =
          this.user.user_code + "" + this.time_stamp + "" + "notify.pdf";
        formData.append("ApiKey", this.ApiKey);
        formData.append("file", this.documentsLinkfiles);
        formData.append("filename", "../HRvecfiles/" + filename);

        result = await this.$http.post("uploadfile.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });

        if (result.data.status == true) {
          this.addtemporarycollegeemreg.temCollegeEmRegDetail = filename;
        } else {
          
        }
        uploaded = true;
      } else {
        uploaded = false;
      }

      this.addtemporarycollegeemreg.ApiKey = this.ApiKey;
      this.addtemporarycollegeemreg.temCollegeEmRegCode = this.user.user_code;
      if (this.addtemporarycollegeemreg.temCollegeEmStatus_sw == true)
        this.addtemporarycollegeemreg.temCollegeEmStatus = "1";
      else this.addtemporarycollegeemreg.temCollegeEmStatus = "0";

      result = await this.$http.post(
        "temporarycollegeemreg.insert.php",
        this.addtemporarycollegeemreg
      );
      ;
      if (result.data.status && uploaded) {
        await this.temporarycollegeemregQueryAll();
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        if (!uploaded) {
          Swal.fire({
            icon: "warning",
            title: "แนบไฟล์ไม่ถูกต้อง",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "บันทึกข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      this.addtemporarycollegeemregdialog = false;
    },
    async temporarycollegeemregEdit(temCollegeEmRegID) {
      let result = await this.$http.post("temporarycollegeemreg.php", {
        ApiKey: this.ApiKey,
        temCollegeEmRegID: temCollegeEmRegID
      });
      this.edittemporarycollegeemreg = result.data;
      if (this.edittemporarycollegeemreg.temCollegeEmStatus == "1")
        this.edittemporarycollegeemreg.temCollegeEmStatus_sw = true;
      else this.edittemporarycollegeemreg.temCollegeEmStatus_sw = false;

      this.edittemporarycollegeemregdialog = true;
    },
    async edittemporarycollegeemregSubmit() {
      let result = "";
      let uploaded = null;

      if (this.$refs.edittemporarycollegeemregform.validate()) {
        this.edittemporarycollegeemreg.ApiKey = this.ApiKey;
        if (this.documentsLinkfilesUpdate != "") {
          let formData = new FormData();
          let filename =
            this.user.user_code + "" + this.time_stamp + "" + "notify.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfilesUpdate);
          formData.append("filename", "../HRvecfiles/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          if (result.data.status == true) {
            this.edittemporarycollegeemreg.temCollegeEmRegDetail = filename;
          } else {
            
          }
          uploaded = true;
        } else {
          uploaded = false;
        }

        result = await this.$http.post(
          "temporarycollegeemreg.update.php",
          this.edittemporarycollegeemreg
        );
        ;
        if (result.data.status == true) {
          this.temporarycollegeemreg = result.data;
          Swal.fire({
            icon: "success",
            title: "แก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.temporarycollegeemregQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "แก้ไขข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.edittemporarycollegeemregdialog = false;
      }
    },
    async temporarycollegeemregDelete(temCollegeEmRegID) {
      let result = await this.$http.post("temporarycollegeemreg.php", {
        ApiKey: this.ApiKey,
        temCollegeEmRegID: temCollegeEmRegID
      });
      this.edittemporarycollegeemreg = result.data;
      this.deletetemporarycollegeemregdialog = true;
    },
    async deleteDocfile() {
      this.edittemporarycollegeemreg.ApiKey = this.ApiKey;
      this.edittemporarycollegeemreg.temCollegeEmRegDetail = "";
      let result;
      result = await this.$http.post("deletefile.php", {
        ApiKey: this.ApiKey,
        filename:
          "../HRvecfiles/" +
          this.edittemporarycollegeemreg.temCollegeEmRegDetail
      });

      result = await this.$http.post(
        "temporarycollegeemreg.update.php",
        this.edittemporarycollegeemreg
      );
      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "แก้ไขข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.temporarycollegeemregQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "แก้ไขข้อมูลผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },

    async deletetemporarycollegeemregSubmit() {
   
      if (this.$refs.deletetemporarycollegeemregform.validate()) {
        let result
        result = await this.$http.post("deletefile.php", {
        ApiKey: this.ApiKey,
        filename: "../HRvecfiles/" + this.edittemporarycollegeemreg.temCollegeEmRegDetail
      });     

        this.edittemporarycollegeemreg.ApiKey = this.ApiKey;
         result = await this.$http.post(
          "temporarycollegeemreg.delete.php",
          this.edittemporarycollegeemreg
        );
        if (result.data.status == true) {
          this.temporarycollegeemreg = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.temporarycollegeemregQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deletetemporarycollegeemregdialog = false;
      }
    }
  },
  computed: {
    time_stamp() {
      const d = new Date();
      let time = d.getTime();
      return time;
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "teal";
    }
  }
};
</script>
